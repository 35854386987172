/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";

function SEO({ description, lang, meta, title, image, location }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        author
                    }
                }
            }
        `
    )
    
    const metaDescription = description;
    const defaultTitle = site.siteMetadata?.title;

    React.useEffect(() => {
        setTimeout(() => {
            var noResult = $('.no-result-page').length;
            var noResultMeta = $('meta[name=robots]').length;
            if (noResultMeta == 0 && noResult > 0) {
                $("head").append("<meta name=robots content=noindex />");
            } else if (noResultMeta > 0 && noResult == 0 && !hasPagination) {
                $('meta[name=robots]').remove();
            }
        }, 1000)
    })

    var currentUrl = '';
    if (location !== undefined && location.pathname) {
        currentUrl = process.env.GATSBY_SITE_URL + location.pathname;
    }
    

    //remove multiple slashes
    currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1");
    //set lowercase url
    currentUrl = currentUrl.toLowerCase();

    // set no index for result pagination
    // set no index for result pagination
    let hasPagination = false;
    if (location !== undefined && location.pathname) {
        let pageStr = location.pathname.split('page-');
        hasPagination = pageStr[1] || false;
        if (pageStr[0] !== undefined) {
            currentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
        }
    }
    if (!currentUrl.endsWith('/'))
        currentUrl = currentUrl + '/';
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            link={[
                {
                    href: currentUrl,
                    rel: "canonical"
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``,
                },
                {
                    name: `twitter:site`,
                    content: "@espace",
                },
                {
                    name: `twitter:card`,
                    content: "summary_large_image",
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:image`,
                    content: image,
                },
                {
                    name: ` twitter:image:src`,
                    content: image,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:domain`,
                    content: "www.espace.q.starberry.com",
                },
                {
                    name: `viewport`,
                    content: "width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
                },
                {
                    name: "apple-mobile-web-app-capable",
                    content : "yes"
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired 
}

export default SEO;
